<template>
    <!-- Modal -->
    <div class="modal fade muySellModal" :class="[{'show' : $parent.copyStrategy && !stopStrategy && !successCopy && !stopStrategyConfirm}]">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content position-relative border-0 radius20">
                <Loader :classname="'innerLoader'" v-if="tradesLoading"></Loader>
                <div class="modal-header radius20 bg-white border-0 pb-0">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="toggleSlidebutton d-inline-flex align-items-center justify-content-center">
                            <h6 class="f-24 bold mb-0" v-if="Object.keys(updatedAccount).length">{{$t('copy_strategy.update')}} {{updatedAccount.leaderActualName}}</h6>
                        </div>
                    </div>
                    <button type="button" class="btn-close" @click="$parent.copyStrategy = false;$parent.openDrop = '';updatedAccount = {};amount = 0"></button>
                </div>
                <div class="modal-body pb-2">
                    <div class="flex-between align-items-start border-bottom pb-2 mb-2" v-if="Object.keys(updatedAccount).length">
                         <div class="tottalValue">
                            <div class="d-flex align-items-center">
                                <v-lazy-image width="30" v-if="updatedAccount.brokerLogo" :src="'https://zulutrade.com/'+updatedAccount.brokerLogo" :alt="updatedAccount.username" />
                                <p class="mb-0 f-14 ms-1">{{updatedAccount.username}}</p>
                            </div>
                            <p class=" mb-0">{{$t('changes.text44')}} {{COMMON.getBaseCurrencySymbol()}}{{updatedAccount.availableCapital || 0}}</p>
                        </div>
                    </div>
                    <div class="specificPrice">
                        <div class="investmentAmount my-2">
                            <h6 class="mb-2 f-15 regular">{{$t('copy_strategy.investmentAmount')}}</h6>
                            <div class="showPrice">
                                <div class="form-group position-relative">
                                    <input type="text" class="form-control text-start largeFont" v-model="amount" name="amount" @input="onAmountChange();allowedDotComma($event,'amount')" @blur="removeLastDotComma('amount')"/>
                                    <span class="position-absolute f-14 secondary medium right pointer" @click="maxCurrentAmount()">{{$t('copy_strategy.max')}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="investmentAmount my-2"> <!-- ProRata input field -->
                            <h6 class="mb-2 f-15 regular" :title="$t('copy_strategy.zuluTradeAutomatically')">{{$t('copy_strategy.customCopyRatio')}}</h6>
                            <div class="showPrice">
                                <div class="form-group position-relative">
                                    <input type="text" class="form-control text-start largeFont" v-model="copyratio" name="copyratio" @input="allowedDotComma($event,'copyratio')" @blur="removeLastDotComma('copyratio')"/>
                                    <span class="position-absolute f-14 secondary medium right pointer">%</span>
                                </div>
                            </div>
                        </div>
                        <div class="advanceState" :class="advanceState?'active':''">
                            <a @click.prevent="advanceState =!advanceState" class="flex-between mb-0 bg-card dropButton medium" href="javascript:void(0)">{{$t('copy_strategy.advancedSettings')}} <vue-feather size="15" type="chevron-down"></vue-feather></a>
                            <div class="dropdown_menu_animated position-relative box-shadow-none" :class="advanceState?'show':''">
                                <div class="form-group position-relative mb-4">
                                    <h6 class="f-15 regular">{{$t('copy_strategy.takeProfit')}}</h6>
                                    <div class="position-relative updateInput">
                                        <label class="upperInputNew ">{{COMMON.getBaseCurrencySymbol()}}</label>
                                        <input type="text" class="form-control" v-model="takeProfitPrice" name="takeProfitPrice" @input="allowedDotComma($event,'takeProfitPrice')" @blur="removeLastDotComma('takeProfitPrice')"/>
                                    </div>
                                </div>
                                <div class="stopLoss flex-between border-bottom pb-2 mb-2">
                                    <p class="mb-0 medium">{{$t('copy_strategy.trailingStopLoss')}}</p>
                                    <label class="switch">
                                        <input type="checkbox" v-model="stopLoss" />
                                        <span class="slider round"></span>
                                    </label>
                                </div>
                                <!-- <div class="stopLoss flex-between" v-if="!isAlreadyCopied">
                                    <p class="mb-0 medium">Copy Open Trades</p>
                                    <label class="switch">
                                        <input type="checkbox" v-model="copytrades" />
                                        <span class="slider round"></span>
                                    </label>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footers p-3 border-0 d-flex align-items-center">
                    <a href="javascript:void(0)" class="button w-50 d-flex bold fillBtn border-button zulu_btn me-2" @click="stopStrategyConfirm=true">{{$t('copy_strategy.stopStrategy')}}</a>
                    <a href="javascript:void(0)" v-if="store.userSelectedAccount?.isLeader" @click="cantCopy()" class="disabled button w-50 d-flex bold fillBtn zulu_btn me-2">{{$t('copy_strategy.update')}}</a>
                    <a href="javascript:void(0)" v-else class="button w-50 d-flex bold fillBtn zulu_btn me-2"  @click="updateStategy()" :class="[{'disabled' : !amount || amount <= 0 || !copyratio || copyratio <= 0 || copyratio > 5000}]">{{$t('copy_strategy.update')}}</a>
                </div>
            </div>
        </div>
    </div>
    <!-- successfully bought -->
    <div class="modal fade muySellModal" :class="[{'show' : successCopy}]">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-white border-0 pb-0">
                    <button type="button" class="btn-close" @click="successCopy = false;$parent.copyStrategy = false"></button>
                </div>
                <div class="modal-body mb-2">
                    <div class="text-center mb-3">
                        <v-lazy-image width="100" class="mb-3" src="/assets/images/success14789.png" :alt="$t('copy_strategy.success')" />
                        <!-- <h5>You have successfully <span class="secondary">bought</span></h5> -->
                        <h5 class="mb-4">{{$t('copy_strategy.youareUpdated')}} <span class="secondary">{{updatedAccount.leaderActualName}}</span></h5>
                    </div>
                    <div class="successMessage bg-card radius10 flex-between p-2 my-2">
                        <div class="d-flex align-items-center">
                            <div class="vueRisk">
                                <span class="d-flex align-items-center justify-content-center me-2">
                                    <v-lazy-image height="40" width="40" class="rounded-circle border" :src="static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + $parent.leaderData.providerId + '&ignore=false'"
                                        :alt="$t('copy_strategy.alt_title')"
                                        :title="$t('copy_strategy.alt_title')"
                                    />
                                </span>
                            </div>
                            <p class="medium mb-0">{{ $parent.leaderData.providerName }}</p>
                        </div>
                        <p class="mb-0 f-18 neutralGrey medium beforeBorder me-1">{{COMMON.getBaseCurrencySymbol()}}{{amount || 0}}</p>
                    </div>
                </div>
                <div class="modal-footers p-3 border-0">
                    <a href="javascript:void(0)" @click="successCopy = false;$parent.copyStrategy = false" class="button d-flex bold fillBtn zulu_btn">{{$t('copy_strategy.continue')}}</a>
                </div>
            </div>
        </div>
    </div>
    <!-- Stop--->
    <div class="modal fade muySellModal" :class="[{'show' : stopStrategy}]">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-white border-0 pb-0">
                    <button type="button" class="btn-close" @click="stopStrategy = false;$parent.copyStrategy = false"></button>
                </div>
                <div class="modal-body mb-2">
                    <div class="text-center mb-3">
                        <v-lazy-image width="100" class="mb-3" src="/assets/images/success14789.png" :alt="$t('copy_strategy.success')" />
                        <h5><span class="secondary">{{$t('copy_strategy.stop')}}</span> {{$t('copy_strategy.strategySuccessfully')}}</h5>
                    </div>
                    <div class="successMessage radius10 bg-card flex-between p-2 mb-2">
                        <div class="d-flex align-items-center">
                            <div class="vueRisk">
                                <span class="d-flex align-items-center justify-content-center me-3">
                                    <v-lazy-image  height="40" width="40"  class="rounded-circle border" :src="static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + $parent.leaderData.providerId + '&ignore=false'"
                                    :alt="$t('copy_strategy.alt_title')"
                                    :title="$t('copy_strategy.alt_title')"
                                    />
                                </span>
                            </div>
                            <p class="medium mb-0">{{ $parent.leaderData.providerName }}</p>
                        </div>
                        <p class="mb-0 f-18 neutralGrey medium me-1 beforeBorder">{{COMMON.getBaseCurrencySymbol()}}{{amount || 0}}</p>
                    </div>
                </div>
                <div class="p-3 border-0">
                    <a href="javascript:void(0)" @click="stopStrategy = false;$parent.copyStrategy = false" class="button d-flex bold fillBtn zulu_btn">{{$t('copy_strategy.continue')}}</a>
                </div>
            </div>
        </div>
    </div>
    <!-- Stop strategy Confirm-->
    <div class="modal fade muySellModal" :class="[{'show' : stopStrategyConfirm}]">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-white border-0">
                    <div class="d-flex align-items-center justify-content-center">
                        <div class="toggleSlidebutton d-inline-flex align-items-center justify-content-center">
                            <h6 class="f-24 bold mb-0">{{$t('copy_strategy.stopStrategy')}}</h6>
                        </div>
                    </div>
                    <button type="button" class="btn-close" @click="stopStrategyConfirm = false"></button>
                </div>
                <div class="modal-body mb-2">
                    <div class="text-center">
                        <p class="mb-2">{{$t('copy_strategy.closeAllTradesfrom')}} <b class="secondary">{{ $parent.leaderData.providerName }}</b> ?</p>
                        <p>{{$t('copy_strategy.byChoosingNo')}}</p>
                    </div>
                </div>
                <div class="p-3 border-0 flex-between">
                    <a href="javascript:void(0)" @click="stopStategy('yes')" class="button me-2 w-50 d-flex bold fillBtn zulu_btn">{{$t('copy_strategy.yes')}}</a>
                    <a href="javascript:void(0)" @click="stopStategy('no')" class="button w-50 d-flex bold borderBtn zulu_btn">{{$t('copy_strategy.no')}}</a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import { useToast } from "vue-toastification";
    import _ from 'lodash'
    export default {
        setup() {
            const store = myStore();
            const toast = useToast();
            return { store, toast };
        },
        data() {
            return {
                amount: 0,
                stopLoss: false,
                copytrades: false,
                takeProfitPrice: 0,
                copyratio: 0,
                isAlreadyCopied: false,
                successCopy: false,
                stopStrategy: false,
                advanceState: false,
                showAccount : false,
                updatedAccount : {},
                stopStrategyConfirm: false,
                tradesLoading: false
            };
        },
        watch: {
            'store.copyTraders'() {
                this.defaultValues()
            }
        },
        methods: {
            cantCopy(){
                this.toast.info(this.static_vars.leaderCantCopy);
            },
            changeBroker(list){
                this.showAccount = false
                this.store.$patch({'userSelectedAccount' : list})
            },
            stopStategy(ans) {
                this.store.stopStategy({}, true, this.$parent.leaderData.providerId, this.updatedAccount.zuluAccountId,'stopStrategy',ans=='yes'?'yes':'no',this).then(() => {
                    this.stopStrategy = true;
                    this.stopStrategyConfirm = false;
                    // this.successCopy = true
                    // let data = this.store.traderDetail;
                    // data.user.following = false;
                    // this.store.$patch({ traderDetail: data });
                    this.$parent.openDrop = ''
                    this.getCopyLeaders()
                });
            },
            getCopyLeaders(){
                this.store.GetCopyTraders({},false)
                // this.store.getCopyStats({},false,this.$parent.leaderData.zuluAccountId)
            },
            updateStategy() {
                let blockedIDs = ["371351", "371319", "371318", "371602", "371197"];
                if (!blockedIDs.includes(this.$parent.openDrop)) {
                    let form = {
                        capitalProtectionAmount: this.COMMON.replaceCommaToDot(this.amount), // The amount to invest
                        capitalProtectionModeId: this.stopLoss ? 2 : 1, // 1 default, 2 for trailing stop loss
                        providerId: this.$parent.leaderData.providerId, // The leader's id
                        stopOnProfit: this.COMMON.replaceCommaToDot(this.takeProfitPrice), // for take profit value,
                    };
                    if (!isNaN(this.copyratio) && parseFloat(this.copyratio) > 0) {
                        form["prorataPercent"] = this.COMMON.replaceCommaToDot(this.copyratio);
                    }
                    this.store.updateLeader(form, true, this.updatedAccount.zuluAccountId, this.copytrades,this).then(() => {
                        this.successCopy = true;
                        this.$parent.openDrop = ''
                        this.getCopyLeaders()
                    });
                }
            },
            maxCurrentAmount(){
                if(this.store.copyTraders?.availableCapitals && Object.keys(this.store.copyTraders.availableCapitals).length){
                    for (let id in this.store.copyTraders.availableCapitals){
                        if(this.store.userSelectedAccount?.brokerAccountId){
                            if(id==this.store.userSelectedAccount.brokerAccountId){
                                this.amount = this.store.copyTraders?.availableCapitals[id] + parseFloat(this.updatedAccount?.capitalProtectionAmount)
                            }
                        }
                    }
                }
            },
            onAmountChange: _.debounce(function() {
                let amount = this.COMMON.replaceCommaToDot(this.amount)
                if(amount && amount > 0){
                    let payload = {
                        "providerId": this.$parent.leaderData.providerId,
                        "capitalProtectionAmount": amount
                    }
                    this.store.prorata(payload,false,this.store.userSelectedAccount.zuluAccountId).then((res)=>{
                        if(res){ this.copyratio = res }
                    })
                }
            },500),
            allowedDotComma(e,f){
                let value = e.target.value.replace(/[^0-9.,]/g, '');
                // Allow only one dot or one comma, but not both
                if (value.indexOf('.') !== -1 && value.indexOf(',') !== -1) {
                    const firstDot = value.indexOf('.');
                    const firstComma = value.indexOf(',');
                    if (firstDot < firstComma) {
                        value = value.replace(/,/, '');
                    } else {
                        value = value.replace(/\./, '');
                    }
                }
                // Allow only one dot
                if (value.split('.').length > 2) {
                    value = value.replace(/\.(?=.*\.)/, '');
                }
                // Allow only one comma
                if (value.split(',').length > 2) {
                    value = value.replace(/,(?=.*,)/, '');
                }
                this[f] = value
            },
            removeLastDotComma(f){
                this[f] = this[f].toString()
                if (this[f] && this[f].endsWith('.') || this[f] && this[f].endsWith(',')) {
                    this[f] = this[f].slice(0, -1);
                }
                if (this[f].startsWith('.') || this[f].startsWith(',')) {
                    this[f] = '0' + this[f];
                }
            },
            defaultValues(){
                if (Object.keys(this.store.copyTraders).length) {
                    if (this.store.copyTraders.providers && this.store.copyTraders.providers.length) {
                        let provider = this.store.copyTraders.providers.filter((i) => (parseInt(i.providerId) == parseInt(this.$parent.leaderData.providerId)) && (parseInt(i.brokerAccountId) == parseInt(this.$parent.leaderData.brokerAccountId)));
                        if (provider.length) {
                            this.amount = provider[0].capitalProtectionAmount;
                            this.takeProfitPrice = provider[0].stopOnProfit;
                            this.stopLoss = provider[0].capitalProtectionTrailing;
                            this.copyratio = provider[0].prorataPercent;
                        } else {
                            this.amount = 0;
                            this.stopLoss = false;
                            this.takeProfitPrice = 0;
                            this.copyratio = 0;
                            this.copytrades = false;
                        }
                    } else {
                        this.amount = 0;
                        this.stopLoss = false;
                        this.takeProfitPrice = 0;
                        this.copyratio = 0;
                        this.copytrades = false;
                    }
                } else {
                    this.amount = 0;
                    this.stopLoss = false;
                    this.takeProfitPrice = 0;
                    this.copyratio = 0;
                    this.copytrades = false;
                }
                if(this.store.copyTraders.providers && this.store.copyTraders.providers.length){
                    let data = this.store.copyTraders.providers.filter(i => parseInt(this.$parent.leaderData.providerId) == parseInt(i.providerId) && (parseInt(i.brokerAccountId) == parseInt(this.$parent.leaderData.brokerAccountId)) );
                    if(data.length){
                        this.store.userTradingAccountsList.map(val => {
                            if(parseInt(data[0].zuluAccountId) == parseInt(val.zuluAccountId)){
                                this.updatedAccount = val
                                this.updatedAccount['leaderActualName'] = data[0].providerName
                                this.updatedAccount['availableCapital'] = data[0].availableCapital
                                this.updatedAccount['capitalProtectionAmount'] = data[0].capitalProtectionAmount
                            }
                        })
                    }else{
                        this.updatedAccount = {}
                    }
                }
            }
        },
        mounted() {
            this.defaultValues()
        },
    };
</script>
<style>
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type="number"] {
        -moz-appearance: textfield;
    }
</style>
