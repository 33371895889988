<template>

    <div clas="col-12">
        <div class="bg-white boxed border-0 p-0">
            <div class="d-flex align-items-center justify-content-between">
                <h4 class="f-22 bold" :class="!isLeaderAccount() ? 'px-3 pt-3' : ''">{{$t('dashboard_trades.text1')}}</h4>
                <!-- Close All Position's start -->
                <!-- <a v-if="tab==1 && getHistoryTrades.length" class="medium me-3 f-14 secondary" href="javascript:void(0)" @click="exitAllTrades=true"><i class="closeHover f-16 pointer me-1 p-0 fa fa-times-circle" aria-hidden="true"></i>{{$t('dashboard_trades.text57')}} {{$parent.$parent.providerClass != -1 ? $t('dashboard_trades.text55') : $t('dashboard_trades.text54')}}</a> -->
                <div class="tooltipbutton wd-200 showRes" v-if="tab==1 && getHistoryTrades.length">
                    <span class="tooltiptext">
                        {{$t('dashboard_trades.text57')}} {{$parent.$parent.providerClass != -1 ? $t('dashboard_trades.text55') : $t('dashboard_trades.text54')}}
                    </span>
                    <a :class="{'disabled' : store.customerDetail?.readOnly}" class="button py-1 zulu_btn rounded small me-3 border-button" href="javascript:void(0)" @click="store.brokerAccountSettings?.ignoreConfirmationPopupWhenClosingPosition ? closeAll() : exitAllTrades=true">
                        Close All
                    </a>
                </div>
                <!-- Close All Position's end -->
            </div>
            <div class="d-flex align-items-center justify-content-between border-bottom">
                <ul id="tabs-nav" class="zuluTabs d-flex align-items-center mb-0">
                    <li :class="[{'active':tab==1}]"><a @click="changeTab(1);tradesTypeData()" href="javascript:void(0)">{{$t('dashboard_trades.text2')}}</a></li>
                    <li :class="[{'active':tab==2}]"><a @click="changeTab(2);tradesTypeData()" href="javascript:void(0)">{{$t('dashboard_trades.text3')}}</a></li>
                    <li :class="[{'active':tab==3}]"><a @click="changeTab(3);tradesTypeData()" href="javascript:void(0)">{{$t('dashboard_trades.text4')}}</a></li>
                </ul>
                <div v-if="tab==1" class="f-14 totalPNL me-3">{{$t('dashboard_trades.text5')}}: <b :class="(totalPNL() >= 0) ? 'green' : 'red'">
                    {{(totalPNL() >= 0) ? '' : '-'}}{{getBaseCurrencySymbol() ? getBaseCurrencySymbol() : '$'}}{{Math.abs(totalPNL())}}</b>
                </div>
                <div v-if="tab==3" class="f-14 totalPNL me-3">{{$t('dashboard_trades.text58')}}: <b :class="(totalPNLclosed() >= 0) ? 'green' : 'red'">
                        {{(totalPNLclosed() >= 0) ? '' : '-'}}{{getBaseCurrencySymbol() ? getBaseCurrencySymbol() : '$'}}{{Math.abs(totalPNLclosed())}}</b>
                </div>
            </div>
            <div class="tabOne py-3" v-if="tab==1">
                <div class="dataTable table-responsive position-relative px-3" v-if="getHistoryTrades.length">
                    <table id="example" class="table currencyTable dashTableUnrealized">
                        <Loader :classname="'innerLoader'" v-if="store.tradesLoading"></Loader>
                        <thead>
                            <tr>
                                <th class="width-attr desc">{{$t('dashboard_trades.text6')}}</th>
                                <th class="pointer desc">{{$t('dashboard_trades.text7')}}</th>
                                <!-- <th class="pointer desc">Date Open</th> -->
                                <th>{{$t('dashboard_trades.text8')}}</th>
                                <th>{{$t('dashboard_trades.text9')}}</th>
                                <th>Pips</th>
                                <th class="desc">{{$t('dashboard_trades.text10')}}</th>
                                <th class="desc">{{$t('dashboard_trades.text11')}}</th>
                                <th class="desc">{{$t('dashboard_trades.text12')}}</th>
                                <th class="desc">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item,key in getHistoryTrades" :key="key">
                                <td>
                                    <div class="d-flex align-items-center">
                                        <div class="d-flex align-items-center p-0 me-1 flex-shrink-0">
                                            <v-lazy-image
                                                width="30"
                                                height="30"
                                                class="euCurrency"
                                                :src="static_vars.marketImageSURL+item.currencyName.toUpperCase().replace(/\//g, '')+'.svg'"
                                                :alt="item.currencyName.toString().split('/')[0].toLowerCase()"
                                                :title="item.currencyName.toString().split('/')[0].toLowerCase()"
                                            />
                                        </div>
                                        <div class="nameView p-0">
                                            <div class="d-flex align-items-center">
                                                <p class="mb-0 f-14 medium">{{item.currencyName || ''}}</p>
                                            </div>
                                            <div class="d-flex align-items-center">
                                                <p class="mb-0 midgray f-11 medium">{{nameDateFormat(item.dateTime)}}</p>
                                                <span class="status" :class="item.tradeType=='BUY'?'green':'sell'">{{item.tradeType || ''}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>{{item.providerName || ''}}</td>
                                <!--  <td>{{dateDateFormat(item.dateTime)}} <span class="d-block">{{timeDateFormat(item.dateTime)}}</span></td> -->
                                <td>{{(item.stdLotds || 0).toFixed(2)}}</td>
                                <td>{{item.amount || 0}}</td>
                                <td :class="(COMMON.getPNL(item) > 0) ? 'green' : 'red'">{{Math.abs(COMMON.getRealTimePips(item)) || 0}}</td>
                                <td>{{item.entryRate || 0}}</td>
                                <td>{{livePrice(item.currencyName,item.tradeType=='BUY' ? 'buy' : 'sell',item.entryRate)}}</td>
                                <td :class="(COMMON.getPNL(item) > 0) ? 'green' : 'red'">{{(COMMON.getPNL(item) > 0) ? '' : '-'}}{{getBaseCurrencySymbol() ? getBaseCurrencySymbol() : '$'}}{{Math.abs(COMMON.getPNL(item)) || 0}}</td>
                                <td class="">
                                    <div class="d-flex align-items-center">
                                        <i class="f-20 me-4 fa fa-spinner fa-spin" v-if="store.brokerTicketsList.length && store.brokerTicketsList.includes(item.brokerTicket)"></i>
                                        <div class="d-flex align-items-center" v-else>
                                            <i @click="store.brokerAccountSettings?.ignoreConfirmationPopupWhenClosingPosition ? exitTrade(item) : exitObject=item" class="closeHover f-20 pointer me-4 p-0 fa fa-times-circle" aria-hidden="true" :title="$t('dashboard_trades.text13')"  :class="{'disabled' : store.customerDetail?.readOnly}"></i>
                                            <div class="position-relative dropdown">
                                                <button class="morebtn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <i class="f-16 fa fa-ellipsis-v" aria-hidden="true"></i>
                                                </button>
                                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                    <li><a class="dropdown-item f-14" href="javascript:void(0)" @click="showTradeDetailPop!=item.brokerTicket ? showTradeDetailPop=item.brokerTicket : showTradeDetailPop=-1;fullDetail(item.brokerTicket,item.brokerAccountId);listTradeDetail=item">{{$t('dashboard_trades.text14')}}</a></li>
                                                    <li><a class="dropdown-item f-14" href="javascript:void(0)" @click="updateSLTPPopPop!=item.brokerTicket ? updateSLTPPopPop=item.brokerTicket : updateSLTPPopPop=-1;updateSLTPdetails=item;openTradeSLTP(item)">{{$t('dashboard_trades.text15')}}</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else class="nodata d-flex align-items-center justify-content-center flex-column">
                    <v-lazy-image class="d-block mb-2" src="/assets/images/noOrder.png" :alt="$t('no_data.nodataAlt')" />
                    <p class="midgray">{{$t('dashboard_trades.text16')}} <router-link class="secondary" :to="{name:'rates'}">{{$t('dashboard_trades.text17')}}</router-link> {{$t('dashboard_trades.text18')}}</p>
                </div>
            </div>
            <div class="tabOne py-3" v-if="tab==2">
                <div class="dataTable table-responsive position-relative px-3" v-if="getHistoryTrades.length">
                    <table id="example" class="table currencyTable dashTableUnrealized">
                        <Loader :classname="'innerLoader'" v-if="store.tradesLoading"></Loader>
                        <thead>
                            <tr>
                                <th width="250" class="width-attr pointer desc">{{$t('dashboard_trades.text19')}}</th>
                                <th class="pointer desc">{{$t('dashboard_trades.text20')}}</th>
                                <th class="pointer desc">{{$t('dashboard_trades.text21')}}</th>
                                <!-- <th class="pointer desc">Order Open</th> -->
                                <th class="pointer desc">{{$t('dashboard_trades.text22')}}</th>
                                <th>{{$t('dashboard_trades.text23')}}</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item,key in getHistoryTrades" :key="key">
                                <td colspan="10" class="border-0 p-0" :class="[{'activelight' : showTradeDetailPop==item.brokerTicket}]">
                                    <tr>
                                        <table class="table megaDropInnerTable m-0 fixed-layout">
                                            <td class="width-attr" width="250">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex align-items-center p-0 me-1 flex-shrink-0">
                                                        <v-lazy-image
                                                            width="30"
                                                            height="30"
                                                            class="euCurrency"
                                                            :src="static_vars.marketImageSURL+item.currencyName.toUpperCase().replace(/\//g, '')+'.svg'"
                                                            :alt="item.currencyName.toString().split('/')[0].toLowerCase()"
                                                            :title="item.currencyName.toString().split('/')[0].toLowerCase()"
                                                        />
                                                    </div>
                                                    <div class="nameView p-0">
                                                        <div class="d-flex align-items-center">
                                                            <p class="mb-0 f-14 medium">{{item.currencyName || ''}}</p>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <p class="mb-0 midgray f-11 medium">{{nameDateFormat(item.dateTime)}}</p>
                                                            <span class="status" :class="item.tradeType=='BUY'?'green':'sell'">{{item.tradeType || ''}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{item.providerName || ''}}</td>
                                            <td>{{(item.amount || 0).toFixed(2)}}</td>
                                            <!-- <td>{{dateDateFormat(item.dateTime)}} <span class="d-block">{{timeDateFormat(item.dateTime)}}</span></td> -->
                                            <td>{{(item.entryRate || 0).toFixed(2)}}</td>
                                            <td>{{(item.interest || 0).toFixed(2)}}</td>
                                            <td class="">
                                                <div class="d-flex align-items-center">
                                                    <i class="f-20 me-4 fa fa-spinner fa-spin" v-if="store.brokerTicketsList.length && store.brokerTicketsList.includes(item.brokerTicket)"></i>
                                                    <div class="d-flex align-items-center" v-else>
                                                        <i @click="store.brokerAccountSettings?.ignoreConfirmationPopupWhenClosingPosition ? exitTrade(item) : exitObject=item" class="closeHover f-20 pointer me-4 p-0 fa fa-times-circle" aria-hidden="true" :title="$t('dashboard_trades.text24')"></i>
                                                        <div class="position-relative dropdown">
                                                            <button class="morebtn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i class="f-16 fa fa-ellipsis-v" aria-hidden="true"></i>
                                                            </button>
                                                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                                <li><a class="dropdown-item f-14" href="javascript:void(0)" @click="showTradeDetailPop!=item.brokerTicket ? showTradeDetailPop=item.brokerTicket : showTradeDetailPop=-1;fullDetail(item.brokerTicket,item.brokerAccountId);listTradeDetail=item">{{$t('dashboard_trades.text14')}}</a></li>
                                                                <li><a class="dropdown-item f-14" href="javascript:void(0)" @click="updateSLTPPopPop!=item.brokerTicket ? updateSLTPPopPop=item.brokerTicket : updateSLTPPopPop=-1;updateSLTPdetails=item;openTradeSLTP(item)">{{$t('dashboard_trades.text15')}}</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </table>
                                    </tr>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="store.providerPendingTrades.totalElements > 8" class="tableFooter d-flex align-items-center justify-content-end mt-1">
                        <Pagination :pageNo="page" :totalRecords="store.providerPendingTrades.totalElements" :perPage="limit" type="dashboardTrades"></Pagination>
                    </div>
                </div>
                <div v-else class="nodata d-flex align-items-center justify-content-center flex-column">
                    <v-lazy-image class="d-block mb-2" src="/assets/images/noOrder.png" :alt="$t('no_data.nodataAlt')" />
                    <p class="midgray">{{$t('dashboard_trades.text25')}}</p>
                </div>
            </div>
            <div class="tabOne py-3" v-if="tab==3">
                <div class="dataTable table-responsive position-relative px-3" v-if="getHistoryTrades.length">
                    <table id="example" class="table currencyTable dashTableUnrealized">
                        <Loader :classname="'innerLoader'" v-if="store.tradesLoading"></Loader>
                        <thead>
                            <tr>
                                <th width="250" class="width-attr pointer desc">{{$t('dashboard_trades.text26')}}</th>
                                <th class="pointer desc">{{$t('dashboard_trades.text28')}}</th>
                                <th class="pointer desc">{{$t('dashboard_trades.text29')}}</th>
                                <th>{{$t('dashboard_trades.text30')}}</th>
                                <th class="pointer desc">{{$t('dashboard_trades.text31')}}</th>
                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item,key in getHistoryTrades" :key="key">
                                <td colspan="10" class="border-0 p-0" :class="[{'activelight' : showTradeDetailPop==item.brokerTicket}]">
                                    <tr>
                                        <table class="table megaDropInnerTable m-0 fixed-layout">
                                            <td class="width-attr" width="250">
                                                <div class="d-flex align-items-center">
                                                    <div class="d-flex align-items-center p-0 me-1 flex-shrink-0">
                                                        <v-lazy-image
                                                            width="30"
                                                            height="30"
                                                            class="euCurrency"
                                                            :src="static_vars.marketImageSURL+item.currency.toUpperCase().replace(/\//g, '')+'.svg'"
                                                            :alt="item.currency.toString().split('/')[0].toLowerCase()"
                                                            :title="item.currency.toString().split('/')[0].toLowerCase()"
                                                        />
                                                    </div>
                                                    <div class="nameView p-0">
                                                        <div class="d-flex align-items-center">
                                                            <p class="mb-0 f-14 medium">{{item.currency || ''}}</p>
                                                        </div>
                                                        <div class="d-flex align-items-center">
                                                            <p class="mb-0 midgray f-11 medium">{{nameDateFormat(item.dateOpen)}}</p>
                                                            <span class="status" :class="item.tradeType=='BUY'?'green':'sell'">{{item.tradeType || ''}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{item.providerName || ''}}</td>
                                            <td :class="item.netPnl>0?'green':'red'">{{formatPrice(item.netPnl || 0)}}</td>
                                            <td>{{dateDateFormat(item.dateOpen)}} <span class="d-block">{{timeDateFormat(item.dateOpen)}}</span></td>
                                            <td>{{dateDateFormat(item.dateClosed)}} <span class="d-block">{{timeDateFormat(item.dateClosed)}}</span></td>
                                            <td class="">
                                                <div class="d-flex align-items-center">
                                                    <div class="position-relative d-flex p-0">
                                                        <div class="position-relative dropdown">
                                                            <button class="morebtn dropdown-toggle" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i class="f-16 fa fa-ellipsis-v" aria-hidden="true"></i>
                                                            </button>
                                                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                                                                <li><a class="dropdown-item f-14" href="javascript:void(0)" @click="showTradeDetailPop!=item.brokerTicket ? showTradeDetailPop=item.brokerTicket : showTradeDetailPop=-1;fullDetail(item.brokerTicket,store.userSelectedAccount.brokerAccountId);listTradeDetail=item">{{$t('dashboard_trades.text14')}}</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </table>
                                    </tr>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="store.providerHistoryTrades.totalElements > 8" class="tableFooter d-flex align-items-center justify-content-end mt-1">
                        <Pagination :pageNo="page" :totalRecords="store.providerHistoryTrades.totalElements" :perPage="limit" type="dashboardTrades"></Pagination>
                    </div>
                </div>
                <div v-else class="nodata d-flex align-items-center justify-content-center flex-column">
                    <v-lazy-image class="d-block mb-2" src="/assets/images/noOrder.png" :alt="$t('no_data.nodataAlt')" />
                    <p class="midgray">{{$t('dashboard_trades.text32')}}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="modal show fade" style="display: block;" v-if="showTradeDetailPop==listTradeDetail.brokerTicket && Object.keys(store.openTradeFullDetail).length">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-white border-0">
                    <h5 class="modal-title">{{$t('dashboard_trades.text14')}}</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="showTradeDetailPop=-1"></button>
                </div>
                <div class="modal-body pt-0 px-0">
                    <div class="additionalInfo px-3">
                        <ul class="mb-0 avgLists">
                            <li class="d-flex align-items-center">
                                <span class="neutralGrey f-14 nameTrade">{{$t('dashboard_trades.text33')}}</span>
                                <p class="mb-0 f-14 menutext medium">{{tab=='3' ? (listTradeDetail.currency || '') : (listTradeDetail.currencyName || '')}}</p>
                            </li>
                            <li class="d-flex align-items-center">
                                <span class="neutralGrey f-14 nameTrade">{{$t('dashboard_trades.text34')}}</span>
                                <p class="mb-0 f-14 menutext" :class="listTradeDetail.tradeType=='BUY'?'green':'red'">{{listTradeDetail.tradeType || ''}}</p>
                            </li>
                            <li class="d-flex align-items-center">
                                <span class="neutralGrey f-14 nameTrade">{{$t('dashboard_trades.text35')}}</span>
                                <p class="mb-0 f-14 menutext">{{listTradeDetail.providerName || ''}}</p>
                            </li>
                            <li class="d-flex align-items-center">
                                <span class="neutralGrey f-14 nameTrade">{{$t('dashboard_trades.text36')}}</span>
                                <p class="mb-0 f-14 menutext">{{tab=='3' ? (generalDateFormat(listTradeDetail.dateOpen)) : generalDateFormat(listTradeDetail.dateTime)}}</p>
                            </li>
                            <li v-if="listTradeDetail.floatingPnl" class="d-flex align-items-center">
                                <span class="neutralGrey f-14 nameTrade">{{$t('dashboard_trades.text37')}}</span>
                                <p class="mb-0 bold" :class="(COMMON.getPNL(listTradeDetail) > 0) ? 'green' : 'red'">{{(COMMON.getPNL(listTradeDetail) > 0) ? '' : '-'}}{{getBaseCurrencySymbol()}}{{Math.abs(COMMON.getPNL(listTradeDetail))}}</p>
                            </li>
                            <li class="d-flex align-items-center">
                                <span class="neutralGrey f-14 nameTrade">{{$t('dashboard_trades.text38')}}</span>
                                <p class="mb-0 f-14 menutext">{{(listTradeDetail.amount || 0).toFixed(2)}}</p>
                            </li>
                            <li class="d-flex align-items-center" v-if="listTradeDetail.roi">
                                <span class="neutralGrey f-14 nameTrade">{{$t('dashboard_trades.text39')}} %</span>
                                <p class="mb-0 f-14 menutext" :class="listTradeDetail.roi > 0?'green':'red'">{{(listTradeDetail.roi || 0).toFixed(2)}} %</p>
                            </li>
                            <li class="d-flex align-items-center bg-card p-2 tabUpdate br-8" v-if="tab != 3">
                                <div class="d-flex align-items-center flex-wrap">
                                    <div class="logInner d-flex align-items-center w-100">
                                        <span class="neutralGrey f-14 nameTrade">{{$t('dashboard_trades.text40')}}</span>
                                        <p class="mb-0 f-14 menutext">{{listTradeDetail.stop || 0}}</p>
                                    </div>
                                    <div class="logInner d-flex align-items-center w-100">
                                        <span class="neutralGrey f-14 nameTrade">{{$t('dashboard_trades.text41')}}</span>
                                        <p class="mb-0 f-14 menutext">{{listTradeDetail.limit || 0}}</p>
                                    </div>
                                </div>
                                <div class="editButton d-flex justify-content-end">
                                    <a class="button zulu_btn rounded border-button f-14 d-flex align-items-center"  :class="{'disabled' : store.customerDetail?.readOnly}" href="javascript:void(0)" @click="updateSLTPPopPop!=listTradeDetail.brokerTicket ? updateSLTPPopPop=listTradeDetail.brokerTicket : updateSLTPPopPop=-1;updateSLTPdetails=listTradeDetail;openTradeSLTP(listTradeDetail);showTradeDetailPop=-1"><vue-feather type="edit-3" size="18" class="me-1"></vue-feather> {{$t('dashboard_trades.text42')}}</a>
                                </div>
                            </li>
                            <li class="d-flex align-items-center" v-if="tab != 3">
                                <span class="neutralGrey f-14 nameTrade">{{$t('dashboard_trades.text43')}}</span>
                                <p class="mb-0 f-14 menutext">{{listTradeDetail.entryRate}}</p>
                            </li>
                            <li class="d-flex align-items-center" v-if="tab != 3">
                                <span class="neutralGrey f-14 nameTrade">{{$t('dashboard_trades.text44')}}</span>
                                <p class="mb-0 f-14 menutext">{{tab=='3' ? (store.allPrices[listTradeDetail.currency] ? (listTradeDetail.tradeType === 'BUY') ? store.allPrices[listTradeDetail.currency].sellPrice : store.allPrices[listTradeDetail.currency].buyPrice : listTradeDetail.entryRate) : store.allPrices[listTradeDetail.currencyName] ? (listTradeDetail.tradeType === 'BUY') ? store.allPrices[listTradeDetail.currencyName].sellPrice : store.allPrices[listTradeDetail.currencyName].buyPrice : listTradeDetail.entryRate}}</p>
                            </li>
                            <li class="d-flex align-items-center">
                                <span class="neutralGrey f-14 nameTrade">{{$t('dashboard_trades.text45')}}</span>
                                <p class="mb-0 f-14 menutext">{{(store.openTradeFullDetail.interest || 0).toFixed(2)}}</p>
                            </li>
                            <li class="d-flex align-items-center">
                                <span class="neutralGrey f-14 nameTrade">{{$t('dashboard_trades.text46')}}</span>
                                <p class="mb-0 f-14 menutext">{{(listTradeDetail.commission || 0).toFixed(2)}}</p>
                            </li>

                            <!-- <li class="d-flex align-items-center">
                                <span class="neutralGrey f-14 nameTrade">Max Profit date</span>
                                <p class="mb-0 f-14 menutext">{{generalDateFormat(store.openTradeFullDetail.maxProfitDate)}}</p>
                            </li>
                            <li class="d-flex align-items-center">
                                <span class="neutralGrey f-14 nameTrade">Worst Drawdown</span>
                                <p class="mb-0 f-14 menutext">{{(store.openTradeFullDetail.worstDrawdown || 0).toFixed(2)}}</p>
                            </li>
                            <li class="d-flex align-items-center">
                                <span class="neutralGrey f-14 nameTrade">Worst Drawdown Date</span>
                                <p class="mb-0 f-14 menutext">{{generalDateFormat(store.openTradeFullDetail.maxDrawdownDate)}}</p>
                            </li> -->
                            <li class="d-flex align-items-center">
                                <span class="neutralGrey f-14 nameTrade">{{$t('dashboard_trades.text47')}}</span>
                                <p class="mb-0 f-14 menutext">{{store.openTradeFullDetail.brokerTicket}}</p>
                            </li>
                        </ul>
                    </div>
                    <div
                        class="tradeLog px-3 mt-3"
                        v-if="store.openTradeFullDetail.transactionDetails && store.openTradeFullDetail.transactionDetails.content && store.openTradeFullDetail.transactionDetails.content.length"
                        >
                        <h6>{{$t('dashboard_trades.text48')}}</h6>
                        <ul class="mb-0 brokeList">
                            <li class="d-flex align-items-center" v-for="log,ind in store.openTradeFullDetail.transactionDetails.content" :key="ind">
                                <span class="neutralGrey f-14 nameTrade">{{log.type}} {{log.value}}</span>
                                <p class="neutralGrey medium d-flex align-items-center mb-0 f-14">{{generalDateFormat(log.date)}}</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal show fade w-430" style="display: block;" v-if="updateSLTPPopPop==updateSLTPdetails.brokerTicket">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-white border-0">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="updateSLTPPopPop=-1"></button>
                </div>
                <div class="modal-body pt-0 px-0 specificPrice pb-0">
                    <div class="showPrice p-3 pt-1 mb-0">
                        <div class="d-flex align-items-center justify-content-between mb-4 blockBottom">
                            <div class="d-flex align-items-center p-0 flex-shrink-0">
                                <v-lazy-image v-if="tab=='3'"
                                    width="30"
                                    height="30"
                                    class="euCurrency"
                                    :src="static_vars.marketImageSURL+updateSLTPdetails.currency.toUpperCase().replace(/\//g, '')+'.svg'"
                                    :alt="updateSLTPdetails.currency.toString().split('/')[0].toLowerCase()"
                                    :title="updateSLTPdetails.currency.toString().split('/')[0].toLowerCase()"
                                />
                                <v-lazy-image v-else
                                    width="30"
                                    height="30"
                                    class="euCurrency"
                                    :src="static_vars.marketImageSURL+updateSLTPdetails.currencyName.toUpperCase().replace(/\//g, '')+'.svg'"
                                    :alt="updateSLTPdetails.currencyName.toString().split('/')[0].toLowerCase()"
                                    :title="updateSLTPdetails.currencyName.toString().split('/')[0].toLowerCase()"
                                />
                                <div class="nameView ms-2  p-0">
                                    <div class="d-flex align-items-center">
                                        <p class="mb-0 f-14 medium">{{tab=='3' ? (updateSLTPdetails.currency) : (updateSLTPdetails.currencyName)}}</p>
                                    </div>
                                </div>
                            </div>
                            <!-- {{COMMON.getBaseCurrencySymbol()}}{{updateSLTPdetails.amount}} -->
                            <div class="d-flex align-items-center">
                                <p class="mb-0 f-15 medium me-2">{{
                                    livePrice(updateSLTPdetails.currencyName,updateSLTPdetails.tradeType=='BUY' ? 'buy' : 'sell',updateSLTPdetails.amount)}}</p>
                                <p class="mb-0 px-1 bold d-inline-flex" v-if="updateSLTPdetails.roi" :class="(parseFloat(updateSLTPdetails.roi) >= 0.0) ? 'greenView' : 'redView'">
                                    {{updateSLTPdetails.roi}}%
                                </p>
                            </div>
                        </div>
                        <label class="upperInput f-14 mb-2">{{$t('dashboard_trades.text49')}}</label>
                        <div class="form-group position-relative mb-3">
                            <!-- <label class="upperInput">{{COMMON.getBaseCurrencySymbol()}}</label> -->
                            <input type="number" class="form-control largeNumber" v-model="stoplossPrice" name = "stoplossPrice" />
                            <span class="position-absolute left pointer line-1" @click="decreaseSL()"><vue-feather size="28" type="minus"></vue-feather></span>
                            <span class="position-absolute right pointer line-1" @click="increaseSL()"><vue-feather size="28" type="plus"></vue-feather></span>
                        </div>
                        <label class="upperInput f-14 mb-2">{{$t('dashboard_trades.text50')}}</label>
                        <div class="form-group position-relative mb-1">
                            <!-- <label class="upperInput">{{COMMON.getBaseCurrencySymbol()}}</label> -->
                            <input type="number" class="form-control largeNumber" v-model="takeProfitPrice" name = "takeProfitPrice" />
                            <span class="position-absolute left pointer line-1" @click="decreaseTP()"><vue-feather size="28" type="minus"></vue-feather></span>
                            <span class="position-absolute right pointer line-1" @click="increaseTP()"><vue-feather size="28" type="plus"></vue-feather></span>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-0 justify-content-center">
                    <a href="javascript:void(0)" v-if="!validSl || !validTP || (Object.keys(store.assetTradingConvesrionData).length && store.assetTradingConvesrionData.isClosed) || store.customerDetail?.readOnly" @click="stopLossWatch();takeProfitWatch();" class="disabledCustom button fillBtn border-button zulu_btn d-flex w-100">{{(Object.keys(store.assetTradingConvesrionData).length && store.assetTradingConvesrionData.isClosed) ? $t('dashboard_trades.text51') : $t('dashboard_trades.text52')}}</a>
                    <a href="javascript:void(0)" v-else class="button fillBtn border-button zulu_btn d-flex w-100" @click="updateSLTP()">{{$t('dashboard_trades.text52')}}</a>
                </div>
            </div>
        </div>
    </div>
    <!-- Exit trade confirm -->
    <div class="modal show fade" style="display: block;" v-if="Object.keys(exitObject).length">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">{{$t('changes.text29')}} <span class="secondary">{{exitObject.currencyName}}</span></h5>
                    <button type="button" class="btn-close" @click="exitObject={}"></button>
                </div>
                <div class="modal-body p-4 border-bottom text-center">
                    <!-- <v-lazy-image height="250" width="250" class="d-block mx-auto mb-4" src="/assets/images/card-icons/congratulationAdded.gif" alt="Payment Method" /> -->
                    <p class="mb-0">{{$t('changes.text30')}}</p>
                </div>
                <div class="modal-footer border-0 justify-content-end">
                    <a href="javascript:void(0)" class="button border-button rounded border-button zulu_btn d-flex" @click="exitObject={}">{{$t('changes.text31')}}</a>
                    <a href="javascript:void(0)" class="button fillBtn zulu_btn d-flex" @click="exitTrade(exitObject)">{{$t('changes.text32')}} </a>
                </div>
            </div>
        </div>
    </div>
    <!-- Exit All positions confirm -->
    <div class="modal show fade" style="display: block;" v-if="exitAllTrades">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header border-0">
                    <h5 class="modal-title">{{$t('dashboard_trades.text57')}}</h5>
                    <button type="button" class="btn-close" @click="exitAllTrades=false"></button>
                </div>
                <div class="modal-body p-4 border-bottom text-center">
                    <p class="mb-0">{{$t('dashboard_trades.text53')}} {{$parent.$parent.providerClass != -1 ? $t('dashboard_trades.text55') : $t('dashboard_trades.text54')}}</p>
                </div>
                <div class="modal-footer border-0 justify-content-end">
                    <a href="javascript:void(0)" class="button border-button rounded border-button zulu_btn d-flex" @click="exitAllTrades=false">{{$t('dashboard_trades.text56')}}</a>
                    <a href="javascript:void(0)" class="button fillBtn zulu_btn d-flex" @click="closeAll()">{{$t('dashboard_trades.text57')}} </a>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import Pagination from "@/components/shared/pagination.vue";
    import { useToast } from "vue-toastification";
    import moment from "moment";
    export default {
        setup() {
            const store = myStore();
            const toast = useToast();
            return { store, toast };
        },
        data() {
            return {
                providerClass: -1,
                openDrop: "",
                tab: 1,
                chartData: [],
                tradeDetail: "",
                listTradeDetail: [],
                showTradeDetailPop: -1,
                updateSLTPPopPop: -1,
                updateSLTPdetails: [],
                tradeLoader: false,
                page: 1,
                limit: 8,
                stoplossPrice : 0,
                takeProfitPrice : 0,
                validSl : true,
                validTP : true,
                exitObject: {},
                exitAllTrades: false,
                returnPipMultuplier: 0,
            };
        },
        components: {
            Pagination,
        },
        watch:{
            '$parent.providerClass'(){
                this.providerClass = this.$parent.providerClass
                if(this.tab != 1){ this.tradesTypeData() }
            },
            '$parent.$parent.providerClass'(){
                this.providerClass = this.$parent.$parent.providerClass
                if(this.tab != 1){ this.tradesTypeData() }
            },
            'stoplossPrice'(){
                if(this.stoplossPrice != this.updateSLTPdetails.stop){
                    let price = this.updateSLTPdetails.entryRate
                    if(this.updateSLTPdetails.tradeType == 'BUY' && parseFloat(this.stoplossPrice) > parseFloat(price)){
                        this.validSl = false
                    } else if(this.updateSLTPdetails.tradeType == 'SELL' && parseFloat(this.stoplossPrice) < parseFloat(price)){
                        this.validSl = false
                    } else {
                        this.validSl = true
                    }
                } else {
                    this.validSl = true
                }
                this.stoplossPrice = parseFloat((this.stoplossPrice).toFixed(this.COMMON.pipDecimal(this.updateSLTPdetails.currencyName)))
            },
            'takeProfitPrice'(){
                if(this.takeProfitPrice != this.updateSLTPdetails.limit){
                    let price = this.updateSLTPdetails.entryRate
                    if(this.updateSLTPdetails.tradeType == 'BUY' && parseFloat(this.takeProfitPrice) < parseFloat(price)){
                        this.validTP = false
                    }else if(this.updateSLTPdetails.tradeType == 'SELL' && parseFloat(this.takeProfitPrice) > parseFloat(price)){
                        this.validTP = false
                    }else{
                        this.validTP = true
                    }
                }else{
                    this.validTP = true
                }
                this.takeProfitPrice = parseFloat((this.takeProfitPrice).toFixed(this.COMMON.pipDecimal(this.updateSLTPdetails.currencyName)))
            }
        },
        computed: {
            getHistoryTrades() {
                if (this.tab == 1 && this.store.providerOpenTrades?.content?.length) {
                    if(this.isLeaderAccount()){
                        return this.store.providerOpenTrades.content;
                    }else{
                        if (this.providerClass != -1) {
                            return this.store.providerOpenTrades.content.filter((val) => {
                                return val.providerId == this.providerClass;
                            });
                        } else {
                            return this.store.providerOpenTrades.content;
                        }
                    }
                } else if (this.tab == 2) {
                    return this.store.providerPendingTrades.content || [];
                } else if (this.tab == 3) {
                    return this.store.providerHistoryTrades.content || [];
                } else {
                    return [];
                }
            },
        },
        methods: {
            isLeaderAccount(){
                if(this.store.allTypeAccount){
                    return false
                } else if(Object.keys(this.store.userSelectedAccount).length){
                    if(this.store.userSelectedAccount.isLeader && this.store.userSelectedAccount.providerStatus=='APPROVED'){
                        return true
                    }else{
                        return false
                    }
                }
            },
            totalPNLclosed(){
                if(this.store.userDashboardDetail && Object.keys(this.store.userDashboardDetail).length){
                    return (parseFloat(this.store.userDashboardDetail.totalPnl || 0) - parseFloat(this.store.userDashboardDetail.floatingPnl || 0)).toFixed(2)
                }
            },
            totalPNL(){
                return this.getHistoryTrades.reduce((sum,a)=>{
                    let pnl = new Number(this.COMMON.getPNL(a))
                    let totalPNL = sum + pnl
                    return totalPNL || 0
                },0).toFixed(2);
            },
            openTradeSLTP(item){
                this.returnPipMultuplier = this.COMMON.pipMultiplier(item.currencyName)
                this.stoplossPrice = this.updateSLTPdetails.stop
                this.takeProfitPrice = this.updateSLTPdetails.limit
                this.conversionUpdateTrade(item)
            },
            decreaseSL(){
                let result = this.stoplossPrice - this.onePip()
                this.stoplossPrice = parseFloat(result)
            },
            increaseSL(){
                let result = this.stoplossPrice + this.onePip()
                this.stoplossPrice = parseFloat(result)
            },
            decreaseTP(){
                let result = this.takeProfitPrice - this.onePip()
                this.takeProfitPrice = parseFloat(result)
            },
            increaseTP(){
                let result = this.takeProfitPrice + this.onePip()
                this.takeProfitPrice = parseFloat(result)
            },
            onePip(){ // Find 1 Pip
                return 1 / this.returnPipMultuplier
            },
            livePrice(currencyName,type,staticPrice){ // return Live Price of instruments
                return this.COMMON.livePriceSymbolList(currencyName,type,staticPrice)
            },
            updateSLTP(){
                if((this.stoplossPrice == this.updateSLTPdetails.stop) && (this.takeProfitPrice == this.updateSLTPdetails.limit)){
                    this.toast.info("No changes made.");
                    this.updateSLTPPopPop = -1
                } else {
                    if(this.stoplossPrice != this.updateSLTPdetails.stop){
                        let payload = { stopValue: this.stoplossPrice };
                        this.store.updateSTOPLOSS(payload,false,this.updateSLTPdetails.brokerTicket,this.updateSLTPdetails.brokerAccountId).then(res=>{
                            if(res){ this.updateSLTPPopPop = -1 }
                        })
                    }
                    if(this.takeProfitPrice != this.updateSLTPdetails.limit){
                        let payload = { limitValue: this.takeProfitPrice };
                        this.store.updateTAKEPROFIT(payload,false,this.updateSLTPdetails.brokerTicket,this.updateSLTPdetails.brokerAccountId).then(res=>{
                            if(res){ this.updateSLTPPopPop = -1 }
                        })
                    }
                }
            },
            stopLossWatch(){
                if(this.stoplossPrice != this.updateSLTPdetails.stop){
                    let price = this.updateSLTPdetails.entryRate
                    if(this.updateSLTPdetails.tradeType == 'BUY' && parseFloat(this.stoplossPrice) > parseFloat(price)){
                        this.toast.error(`Stop Loss should be less than ${price}`);
                    } else if(this.updateSLTPdetails.tradeType == 'SELL' && parseFloat(this.stoplossPrice) < parseFloat(price)){
                        this.toast.error(`Stop Loss should be greater than ${price}`);
                    }
                }
            },
            takeProfitWatch(){
                if(this.takeProfitPrice != this.updateSLTPdetails.limit){
                    let price = this.updateSLTPdetails.entryRate
                    if(this.updateSLTPdetails.tradeType == 'BUY' && parseFloat(this.takeProfitPrice) < parseFloat(price)){
                        this.toast.error(`Take Profit should be greater than ${price}`);
                    }else if(this.updateSLTPdetails.tradeType == 'SELL' && parseFloat(this.takeProfitPrice) > parseFloat(price)){
                        this.toast.error(`Take Profit should be less than ${price}`);
                    }
                }
            },
            getBaseCurrencySymbol() {
                if (Object.keys(this.store.allCurrencySymbols).length && Object.keys(this.store.userSelectedAccount).length) {
                    return this.store.allCurrencySymbols[this.store.userSelectedAccount.baseCurrencyId].htmlSymbol;
                }else{
                    if (this.store.allTypeAccount) {
                        return '$'
                    }
                }
            },
            changeTab(val) {
                this.tab = val;
                this.page = 1;
            },
            tradesTypeData() {
                let postData = {
                    page: this.page - 1,
                    size: this.limit,
                };
                if (Object.keys(this.store.userSelectedAccount).length && this.store.userSelectedAccount.brokerAccountId) {
                    if (this.tab == 1) {
                        this.store.getProviderOpenTrades(postData, true);
                    } else if (this.tab == 2) {
                        this.store.getProviderPendingTrades(postData, true, this.store.userSelectedAccount.brokerAccountId,this.providerClass);
                    } else if (this.tab == 3) {
                        this.store.getProviderHistoryTrades(postData, true, this.store.userSelectedAccount.brokerAccountId,this.providerClass);
                    }
                } else {
                    if (this.store.allTypeAccount) {
                        let data = [];
                        if (this.store.allTypeAccount == "ALL_DEMO") {
                            data = this.store.userTradingAccountsList.filter((i) => i.demo === true);
                        } else if (this.store.allTypeAccount == "ALL_LIVE") {
                            data = this.store.userTradingAccountsList.filter((i) => i.demo === false);
                        }
                        if (data.length) {
                            if (this.tab == 2) {
                                this.store.getProviderPendingTrades(postData, true, data[0].brokerAccountId,this.providerClass);
                            } else if (this.tab == 3) {
                                this.store.getProviderHistoryTrades(postData, true, data[0].brokerAccountId,this.providerClass);
                            }
                        }
                    }
                }
            },
            nameDateFormat(date) {
                return moment(date).format("DD MMM YYYY, hh:mm a");
            },
            generalDateFormat(date) {
                return moment(date).format("DD/MM/YYYY - hh:mm:ss");
            },
            dateDateFormat(date) {
                return moment(date).format("YYYY-MM-DD");
            },
            timeDateFormat(date) {
                return moment(date).format("hh:mm:ss");
            },
            exitTrade(item) {
                this.exitObject = {}
                let tickets = []
                tickets.push(item.brokerTicket)
                this.store.updateBrokerTickets(tickets)
                let form = { requestedPrice: item.currentRate };
                let conversionForm = {
                    currency: item.currencyName,
                    value: 0.01,
                    valueType: "AMOUNT",
                };
                this.store.getConverstionStats(conversionForm, false, (this.store.allTypeAccount) ? item.brokerAccountId : this.store.userSelectedAccount.brokerAccountId).then((res) => {
                    if (res) {
                        if (Object.keys(this.store.assetTradingConvesrionData).length) {
                            if (this.store.assetTradingConvesrionData.isClosed) {
                                this.toast.info("Cannot close trade when Market is closed");
                            } else {
                                this.showTradeDetailPop = -1
                                if (this.tab == 1) {
                                    this.store.closeOpenOrder(form, false, item.brokerTicket, item.brokerAccountId).then((response)=>{
                                        if (response === true) {
                                            this.store.getUserDashboardData({},true,'','10000')
                                            item = {}
                                            this.COMMON.pollingAPIs(1,0,1,'tradesTypeBoth',0)
                                        }
                                    })
                                } else if (this.tab == 2) {
                                    this.store.closePendingOpenOrder({}, true, item.brokerTicket, item.brokerAccountId).then((response) => {
                                        if (response === true) {
                                            this.store.getProviderPendingTrades({}, true, (this.store.allTypeAccount) ? item.brokerAccountId : this.store.userSelectedAccount.brokerAccountId);
                                            this.store.getUserDashboardData({},true,'','10000')
                                            item = {}
                                            this.exitObject = {}
                                            this.COMMON.pollingAPIs(0,1,1,'tradesTypeBoth',0)
                                        }
                                    });
                                }
                            }
                        }
                    }
                });
            },
            formatPrice(price) {
                if (price < 0) {
                    return `-${this.getBaseCurrencySymbol() ? this.getBaseCurrencySymbol() : '$'}${parseFloat(Math.abs(price)).toFixed(2)}`;
                } else {
                    return `${this.getBaseCurrencySymbol() ? this.getBaseCurrencySymbol() : '$'}${parseFloat(price).toFixed(2)}`;
                }
            },
            fullDetail(ticket, targetBrokerAccountId) {
                if (this.showTradeDetailPop == ticket) {
                    this.store.$patch({ openTradeFullDetail: [] });
                    if (this.tab == 1) {
                        this.store.getFullDetailOpenTrade({}, true, ticket, this, targetBrokerAccountId);
                    } else if (this.tab == 2) {
                        this.store.getFullDetailPendingTrade({}, true, ticket, this, targetBrokerAccountId);
                    } else if (this.tab == 3) {
                        this.store.getFullDetailClosedTrade({}, true, ticket, this, targetBrokerAccountId);
                    }
                }
            },
            stopStrat(providerID, zuluaccountID) {
                this.store.stopStategy({}, true, providerID, zuluaccountID, "dash").then(() => {
                    if (Object.keys(this.store.userSelectedAccount).length && this.store.userSelectedAccount.zuluAccountId) {
                        this.store.getCopyStats({}, false, this.store.userSelectedAccount.zuluAccountId);
                    }
                });
            },
            conversionUpdateTrade(item){
                let conversionForm = {
                    currency: item.currencyName,
                    value: 0.01,
                    valueType: "AMOUNT",
                };
                this.store.getConverstionStats(conversionForm, false, (this.store.allTypeAccount) ? item.brokerAccountId : this.store.userSelectedAccount.brokerAccountId).then(res=>{
                    if (res && Object.keys(this.store.assetTradingConvesrionData).length) {
                        if (this.store.assetTradingConvesrionData.isClosed) {
                            this.toast.info("Cannot update trade when Market is closed");
                            this.validSL = false
                        }
                    }
                })
            },
            // Close All Position's
            closeAll(){
                const providerClass = this.$parent.$parent.providerClass;
                const providerId = providerClass !== -1 ? providerClass : 0;
                const providerTrades = this.store.providerOpenTrades.content.filter(val => val.providerId === providerId);
                let ticketList = providerTrades.map(item => item.brokerTicket)
                this.store.updateBrokerTickets(ticketList)
                let payload = {
                    "brokerAccountId": this.store.userSelectedAccount?.brokerAccountId,
                    "providerId": this.$parent.$parent.providerClass != -1 ? this.$parent.$parent.providerClass : 0
                }
                this.store.closeAllPositions(payload,false).then(res=>{
                    if(res){ 
                        this.store.getUserDashboardData({}, false, "", "10000"); 
                        this.COMMON.pollingAPIs(1,0,1,'tradesTypeBoth',0)
                    }
                })
                this.exitAllTrades = false
            }
        },
        created(){
            this.store.getProviderOpenTrades({}, true)
        }
    };
</script>
<style>
    .disabledCustom {
        opacity: 0.5;
    }
</style>